// See uploadWorkflows from 'utilities/uploadWorkflows' for source of truth

import {telemetry} from '../utilities/telemetry'

export const _defaultState = {
  dialogOpen: false,

  uploadWorkflowStage: 0,
  availableWorkflowStages: [0],

  // Data concerning single image upload
  selectedProject: '',
  selectedCollection: '',
  metadataSchema: [],
  metadataContent: {properties: []},
  metadataValidationResults: {errors: [], warnings: []},
  itemAssetFile: null,

  // id of the image upon successful upload. Used in the last stage of the dialog
  imageId: null,
  imageProject: null,
  errorMessage: null,

  // mission-package specific content
  missionPackageDiscoveryConfirmDialog: false,
  missionPackageDiscoveryMode: false,

  // Determing whether an ortho mosaic uploader is validaitng or not
  orthoMosaicValidating: false,

  // Determine whether an experimental uploader is validating or not
  experimentalValidating: false,

  footerMessages: [],
}

export default (state = _defaultState, action) => {
  switch (action.type) {
    case 'OPEN_IMAGE_UPLOADER':
      return {...state, dialogOpen: true}

    case 'CANCEL_IMAGE_UPLOAD':
      return {..._defaultState, cancelReason: action.reason}

    case 'RESET_IMAGE_UPLOAD':
      return {..._defaultState, dialogOpen: true}

    case 'SET_UPLOAD_WORKFLOW_STAGE':
      return {...state, uploadWorkflowStage: action.stage}

    case 'SET_UPLOAD_TYPE':
      return {...state, uploadType: action.uploadType}

    case 'ADD_AVAILABLE_WORKFLOW_STAGES':
      return {
        ...state,
        // Prevent duplicate values in the array
        availableWorkflowStages: Array.from(
          new Set([...state.availableWorkflowStages, ...action.availableStages])
        ),
      }

    case 'SET_AVAILABLE_WORKFLOW_STAGES':
      return {
        ...state,
        availableWorkflowStages: action.availableWorkflowStages,
      }

    case 'FREEZE_AVAILABLE_WORKFLOW_STAGES':
      return {
        ...state,
        availableStages: [state.uploadWorkflowStage[1]],
      }

    case 'SET_ASSET_FILE':
      return {
        ...state,
        itemAssetFile: action.assetFile,
      }

    case 'SET_SELECTED_PROJECT':
      return {
        ...state,
        selectedProject: action.project,
      }
    case 'SET_SELECTED_COLLECTION':
      return {
        ...state,
        selectedCollection: action.collection,
      }

    case 'UPDATE_ITEM_METADATA_VALUES':
      return {
        ...state,
        metadataContent: action.metadataContent,
      }

    case 'SET_UPLOAD_SCHEMA':
      return {
        ...state,
        metadataSchema: action.metadataSchema,
      }

    case 'INDICATE_UPLOAD_SUCCESS':
      return {...state, imageId: action.imageId, imageProject: action.imageProject}

    case 'INDICATE_UPLOAD_FAILURE':
      return {...state, errorMessage: action.errorMessage}

    case 'INDICATE_METADATA_VALIDATION_RESULTS':
      return {
        ...state,
        metadataValidationResults: action.metadataValidationResults,
      }

    case 'SET_MISSION_PACKAGE_DISCOVERY_CONFIRM_DIALOG':
      return {...state, missionPackageDiscoveryConfirmDialog: action.dialogState}

    // Once turned on, it cannot be turned off...
    case 'SET_MISSION_PACKAGE_DISCOVERY_MODE':
      return {...state, missionPackageDiscoveryMode: true}

    case 'SET_ORTHO_MOSAIC_VALIDATING':
      return {
        ...state,
        orthoMosaicValidating: action.validating,
      }

    case 'SET_EXPERIMENTAL_VALIDATING':
      return {
        ...state,
        experimentalValidating: action.validating,
      }

    case 'SET_FOOTER_MESSAGES':
      return {
        ...state,
        footerMessages: action.footerMessages,
      }

    default:
      return state
  }
}
